export default {
  "HomeView": {
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are looking forward to seeing all of you at our wedding on the 7th of May 2023. We've put some information together for you about the day, areas to stay, places to visit, as well as how to get there. We hope you find this useful but please get in contact should you need any help or just have some questions that we can answer."])},
    "GiftList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gift List"])},
    "GiftListDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most of you are traveling from far and wide and so we aren't expecting anything other than your presence but if you would like to give us a present we have put together a gift list. We have a list at John Lewis with a few things and a slightly larger list on Prezola. Links below:"])},
    "JohnLewis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John Lewis"])},
    "Prezola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezola"])},
    "DressCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dress Code"])},
    "DressCodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The dress code for the day is smart casual. If you want to be very smart then please do but feel free to dress in a way that you feel comfortable."])},
    "Venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venue"])},
    "VenueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta dos Gafanhotos"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rua Quinta dos Gafanhotos 173, 2775-734 Carcavelos, Portugal"])},
    "Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Map"])},
    "MapDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta dos Gafanhotos is located west of Lisbon"])},
    "MapOneDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From Lisbon airport to the venue is approximately a 30 minute drive"])},
    "MapTwoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The nearest train station is Carcavelos, which is an 8 minute drive from the station"])},
    "MapThreeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is ample parking allocated for all wedding guest just in front of the main gate to the venue"])},
    "Stay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Places to Stay and Transport Options"])},
    "LisbonDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The orange marked area on the map is the central area of Lisbon. You will find plenty of choices to stay, you will have downtown Lisbon and the main attractions. The train station Cais do Sodre (marked in blue), will take you straight to Carcavelos. The journey takes about 30 minutes. Cais do Sodre also has a tube station should want to stay further out of the centre. By car, from central Lisbon should take about 30 minutes."])},
    "SintraDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintra is a very beautiful place to stay, with lots of historical architecture and lush vegetation, characteristic of the area. It can be quite touristy during the day but it will be quiet at night. There are no good public transport links to the venue from here and so if you do stay in Sintra you will need a car or taxi to get to the venue. The journey should take just under 30 minutes."])},
    "CascaisDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cascais is a charming seaside town. Here you will find plenty of accommodation to choose from. There will be lots of nice restaurants to explore and it will still be lively in the evenings. From here you can get a train to Carcavelos, which takes around 15 minutes. If driving or taking a taxi from Cascais to the venue this will take just under 20 minutes. All the area along the coast between Cascais and Carcavelos will also have good accommodation options to choose from."])},
    "CarcavelosDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carcavelos is the closest area to the venue, it is more of a residential area but there are some accommodation options around, should you wish to stay closer to the venue. There is a beach nearby with some restaurants, cafes and also surf schools, should you be up to catching some waves."])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule of Events"])},
    "Lisbon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisbon"])},
    "Sintra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintra"])},
    "Cascais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cascais"])},
    "Updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will update the site with more information closer to the day. So keep your eyes peeled."])},
    "SeeYouSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See you soon! 🙂"])}
  },
  "ScheduleOfEvents": {
    "ArriveTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2:30 PM"])},
    "ArriveCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrive"])},
    "CeremonyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3:30 PM"])},
    "CeremonyCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceremony"])},
    "TapasTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 PM"])},
    "TapasCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapas"])},
    "PhotosTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["4 PM"])},
    "PhotosCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos"])},
    "DinnerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 PM"])},
    "DinnerCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinner"])},
    "ToastsTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6:30 PM"])},
    "ToastsCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toasts"])},
    "FirstDanceTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6:45 PM"])},
    "FirstDanceCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1st Dance"])},
    "PartyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["7 PM"])},
    "PartyCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Party"])},
    "CakeTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 PM"])},
    "CakeCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cake"])},
    "CarriagesTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["11:30 PM"])},
    "CarriagesCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carriages"])}
  },
  "PaparazziView": {
    "PaparazziGame": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paparazzi Game"])},
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the game "])},
    "Task1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Task 1: Take a photo pulling this face"])}
  },
  "PhotosView": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos"])},
    "PhotosSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photos selected"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "HelloEverybody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello everybody."])},
    "PhotosDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We would really like to thank you for joining us at our wedding. We had the most wonderful and special day, made all the more special by your presence. We hope you had a lovely time and as a memory of the day here are the professional wedding photos. You can download them as you wish."])},
    "PhotosWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To download multiple photos, you only need to click on each photo once and then click 'Download'. If you are not able to download multiple photos at once you may be prompted by your browsers to allow the download of multiple files. Please click 'Allow'."])},
    "PhotosOldHomeView": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["The old web page is still accessible ", _interpolate(_list(0)), "."])},
    "homeView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["here"])},
    "Final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Once again, thank you for joining us and we hope to see each of you again very soon."])},
    "LotsOfLove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lots of Love,"])},
    "IrisAndDaniel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iris ♡ Daniel"])}
  }
}