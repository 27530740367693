export default {
  "HomeView": {
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estamos ansiosos de vos ver a todos no nosso casamento no dia 7 de Maio 2023. Aqui podem encontrar alguma informação sobre a localização da quinta e sobre os horários dos eventos no dia. Esperamos que esta informação seja útil mas por favor entrem em contacto se tiverem algumas perguntas."])},
    "GiftList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lista de Presentes"])},
    "GiftListDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A vossa presença no dia é o mais importante e por isso não esperamos nada mais, mas se de qualquer maneira quiserem contribuir com algo nós criámos uma lista de presentes de casamento. Temos uma pequena lista no John Lewis e outra um pouco maior no Prezola. Links em baixo:"])},
    "JohnLewis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["John Lewis"])},
    "Prezola": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezola"])},
    "DressCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dress Code"])},
    "DressCodeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O dress code para o dia é smart casual (informal mas elegante). Se quiserem se vestir mais elegante podem mas igualmente podem se vestir des forma que se sintam confortáveis."])},
    "Venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta"])},
    "VenueName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta dos Gafanhotos"])},
    "Address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rua Quinta dos Gafanhotos 173, 2775-734 Carcavelos, Portugal"])},
    "Map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapa"])},
    "MapDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quinta dos Gafanhotos está localizada em Carcavelos"])},
    "MapOneDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do centro de Lisboa até à quinta são cerca de 30 minutos de carro"])},
    "MapTwoDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A estação de comboio mais próxima é Carcavelos, com ligações directas a Lisboa (Cais de Sodré) e Cascais. Da estação são aproximadamente 8 minutos de carro"])},
    "MapThreeDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Há estacionamento para os convidados no parque de estacionamento directamente em frente do portão principal de entrada para a quinta"])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horário dos Eventos"])},
    "Lisbon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lisboa"])},
    "Sintra": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sintra"])},
    "Cascais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cascais"])},
    "Updates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nós vamos actualizar o site mais perto da data com os últimos detalhes, por isso voltem mais tarde para ver as últimas actualizações."])},
    "SeeYouSoon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Até breve! 🙂"])}
  },
  "ScheduleOfEvents": {
    "ArriveTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["14:30"])},
    "ArriveCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chegadas"])},
    "CeremonyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15:30"])},
    "CeremonyCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerimónia"])},
    "TapasTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16:00"])},
    "TapasCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapas"])},
    "PhotosTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["16:00"])},
    "PhotosCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos"])},
    "DinnerTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["17:00"])},
    "DinnerCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jantar"])},
    "ToastsTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18:30"])},
    "ToastsCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brindes"])},
    "FirstDanceTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["18:45"])},
    "FirstDanceCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1a Dança"])},
    "PartyTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["19:00"])},
    "PartyCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festa"])},
    "CakeTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["20:00"])},
    "CakeCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bolo"])},
    "CarriagesTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["23:30"])},
    "CarriagesCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fim da festa"])}
  },
  "PhotosView": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos"])},
    "PhotosSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fotos selecionadas"])},
    "Download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download"])},
    "HelloEverybody": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olá a todos."])},
    "PhotosDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nós gostariamos muito de vos agradecer pela vossa presença no nosso casamento. Nós tivemos um dia maravilhoso e especial, tornado ainda mais especial pela vossa presença. Esperamos que tenham passado um belo dia e como memória do dia aqui estão as fotos profissionais do casamento. Podem fazer o download como desejarem."])},
    "PhotosWarning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para fazer o download de várias fotos, basta clicar uma vez em cada foto e fazer o 'Download'. Se não conseguirem fazer o download de várias fotos de uma vez poderão ser solicitados pelo browser para permitir o download. Por favor carreguem em 'Permitir'."])},
    "PhotosOldHomeView": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["A página antiga ainda está acessivél ", _interpolate(_list(0)), "."])},
    "homeView": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aqui"])},
    "Final": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mais uma vez, obrigada por terem vindo e esperamos vos ver em breve."])},
    "LotsOfLove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com muito amor e carinho,"])},
    "IrisAndDaniel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iris ♡ Daniel"])}
  }
}